import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import Hero from "../components/hero"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Publication from "../components/publications/publication"
import FilterBy from "../components/article-preview/filter-by"
import { Box } from "@chakra-ui/react"

const styles = {
  "inner-box": {
    px: { base: "24px", md: "24px", lg: "0px" },
    width: { base: "100%", md: "100%", lg: "538px" },
    margin: "0 auto",
  },
  hero: {
    root: {
      justifyContent: "center",
      px: "0px",
    },
    "left-box": {
      width: "300px",
    },
    image: {
      height: { base: "auto", md: "auto", lg: "300px" },
      width: { base: "100%", md: "100%", lg: "500px" },
      ml: { base: "0px", md: "0px", lg: "26px" },
    },
    heading: {
      textAlign: { base: "center", md: "center", lg: "left" },
    },
  },
}

const PublicationsIndex = props => {
  const _publications = get(props, "data.allContentfulPublication.nodes")
  const [options, setOptions] = React.useState([
    { id: 1, name: "All Publication Types", selected: true },
    { id: 2, name: "Research", selected: false },
  ])

  const handleOnChange = React.useCallback(e => {
    const id = Number(e.target.options[e.target.selectedIndex].value)
    setOptions(prev =>
      prev.map(p => {
        if (p.id === id) return { ...p, selected: true }
        return { ...p, selected: false }
      })
    )
  }, [])
  const selected = React.useMemo(
    () => options.filter(o => o.selected)[0],
    [options]
  )
  const publications = React.useMemo(
    () =>
      selected.id === 1
        ? _publications
        : _publications.filter(p => p?.tags?.includes(selected.name)),
    [_publications, selected]
  )

  return (
    <Layout location={props.location} title={"Research and Publications"}>
      <Hero
        title="Research and Publications"
        Image={() => (
          <StaticImage
            src="../images/blog/blog-bg.png"
            placeholder="blurred"
            alt="Research and Publications"
          />
        )}
        content="Research and publications by our team"
        styles={styles.hero}
      />
      <Box sx={styles["inner-box"]}>
        <FilterBy options={options} onChange={handleOnChange} />
        {publications.map(publication => (
          <Publication
            key={publication?.id}
            title={publication?.title}
            date={publication?.publishDate}
            tags={publication?.tags}
            link={publication?.link?.link}
          />
        ))}
      </Box>
    </Layout>
  )
}

export default PublicationsIndex
export const pageQuery = graphql`
  query PublicationsIndexQuery {
    allContentfulPublication(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        id
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        link {
          link
        }
      }
    }
  }
`
