import React from "react"
import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react"
import Tags from "../tags"

const styles = {
  root: {
    width: "100%",
    height: "auto",
  },
  "inner-box": {
    borderTop: "1px solid #E0E0E0",
    width: "100%",
    my: "44px",
  },
  date: {
    fontSize: "14px",
    lineHeight: "31.92px",
    fontWeight: 400,
    marginBottom: "12px",
  },
  link: {
    _hover: { color: "#1B263F" },
  },
  title: {
    fontSize: { base: "20px", md: "20px", lg: "48px" },
    fontWeight: "bold",
    lineHeight: { base: "28px", md: "28px", lg: "56px" },
    color: "#1B263F",
    mt: "24px",
  },
}

const Publication = ({ title, date, link, tags }) => {
  return (
    <Flex sx={styles.root}>
      <Box sx={styles["inner-box"]}>
        <Link href={link} sx={styles.link} isExternal>
          <Heading sx={styles.title} as="h1">
            {title}
          </Heading>
          <Text sx={styles.date}>{date}</Text>
          <Tags tags={tags} />
        </Link>
      </Box>
    </Flex>
  )
}

export default Publication
